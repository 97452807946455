import { PermissionGroup } from "@/react/types";

const propertyPermissions: PermissionGroup = {
  name: "Property",
  key: "properties",
  description: null,
  permissions: {
    view: {
      name: "View Property",
      key: "property_permission_view",
      defaultValue: true,
      disabled: (_roleSettings, _rolePermissions) => true,
      description: null,
    },
    update: {
      name: "Update Property",
      key: "property_permission_update",
      dependsOn: ["organizations.view_credentials"],
      description: null,
    },
    create: {
      name: "Create Property",
      key: "property_permission_create",
      dependsOn: ["organizations.view_credentials"],
      description: null,
    },
    delete: {
      name: "Delete Property",
      key: "property_permission_delete",
      description: null,
    },
    manage_device_settings: {
      name: "Manage Device Settings",
      key: "property_permission_manage_device_settings",
      description: null,
    },
    manage_business_hours: {
      name: "Manage Business Hours",
      key: "property_permission_manage_business_hours",
      description: null,
    },
    manage_device_wifi_network: {
      name: "Manage Device WiFi Network",
      key: "property_permission_manage_device_wifi_network",
      description: null,
    },
    manage_access_groups: {
      name: "Manage Access Groups",
      key: "property_permission_manage_access_groups",
      description: null,
    },
    manage_vacant_staff_access: {
      name: "Manage Vacant Staff Access Settings",
      key: "property_permission_manage_vacant_staff_access",
      dependsOn: ["users.view"],
      description: null,
    },
    manage_thermostat_guardrails: {
      name: "Manage Thermostat Guardrails",
      key: "property_permission_manage_thermostat_guardrails",
      description: null,
    },
    manage_construction_mode: {
      name: "Manage Construction",
      key: "property_permission_manage_construction_mode",
      description:
        "With this permission, the user will be able to enable property construction mode, apply construction codes to units, and cycle construction codes.",
    },
    manage_categories: {
      name: "Manage Categories",
      key: "property_permission_manage_categories",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_escalations: {
      name: "Manage Escalations",
      key: "property_permission_manage_escalations",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_oncall_schedule: {
      name: "Manage On-Call Schedule",
      key: "property_permission_manage_oncall_schedule",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_packages: {
      name: "Manage Packages",
      key: "property_permission_manage_packages",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_places: {
      name: "Manage Places",
      key: "property_permission_modify_places",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_ivr_announcements: {
      name: "Manage Announcements",
      key: "property_permission_manage_ivr_announcements",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    view_broadcast_messages: {
      name: "View Broadcast Messages",
      key: "property_permission_view_broadcast_messages",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_templates: {
      name: "Manage Templates",
      key: "property_permission_manage_templates",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.smrtCoreUsersEnabled,
    },
    manage_custom_branding_center: {
      name: "Manage Custom Branding Center",
      key: "property_permission_manage_custom_branding_center",
      description: null,
      show: (_organization, _currentUser, _role, props) =>
        props.customBrandingEnabled,
    },
  },
};

export default propertyPermissions;
